<template>
  <!-- 原单 -->
  <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
    <OrderInfoUI :order="order" :peoList="peoList" :confirmLoading="confirmLoading" :cancelLoading="cancelLoading" :phone="phone" @refund="fetchData" @confirm="confirm" @cancel="cancel" />
  </van-pull-refresh>
</template>

<script>
import { mapGetters } from "vuex";
import OrderInfoUI from "./components/PaymentUI";
import { orderStatusReload, applyIssueOrder, cancelOrder } from "@/api/train";
import { fetchPhone } from "@/common/config.js";
export default {
  computed: {
    ...mapGetters(["orderItem"])
  },
  components: { OrderInfoUI },
  data() {
    return {
      phone: "", // 服务商电话
      order: {}, // 订单
      peoList: [], // 人员
      confirmLoading: false, // 按钮转圈圈
      cancelLoading: false,
      isLoading: false, // 刷新
      canRefresh: true // 可否刷新
    };
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      setTimeout(() => {
        this.fetchData(false);
      }, 500);
    },
    // ajax 获取数据
    fetchData(f = true) {
      if (f) this.canRefresh = true;
      this.phone = fetchPhone(this.orderItem.corp); // 更新服务商电话
      if (this.canRefresh)
        orderStatusReload({
          corp: this.orderItem.corp,
          orderNo: this.orderItem.orderNo,
          outOrderNo: this.orderItem.outOrderNo
        }).then(response => {
          this.order = response.order;
          this.peoList = response.orderItem;
          this.isLoading = false;
          this.canRefresh = false;
          setTimeout(() => {
            this.canRefresh = true;
          }, 30 * 1000);
        });
      else {
        this.$notify({
          type: "warning",
          message: "请勿频繁刷新，请30秒后再次尝试。"
        });
        this.isLoading = false;
      }
    },
    // 出票
    confirm() {
      this.confirmLoading = true;
      applyIssueOrder({
        orderNo: this.order.orderNo,
        outOrderNo: this.order.outOrderNo,
        corp: this.order.corp,
        originalOrderNo: this.bz3||"",
        itemId: ""
      })
        .then(response => {
          // 如果经费不足提示。
          if (!response.success) {
            this.confirmLoading = false;
            return this.$toast.fail(response.msg);
          }
          // 成功后需要延迟刷新
          // setTimeout(() => {
            this.$toast.success("申请出票成功，请稍后。");
            this.fetchData();
            this.confirmLoading = false;
          })
            .catch(() => {
              this.confirmLoading = false;
            })
        // }, 10 * 1000);
    },
    // 取消出票
    cancel() {
      this.cancelLoading = true;
      cancelOrder({
        orderNo: this.order.orderNo,
        outOrderNo: this.order.outOrderNo,
        corp: this.order.corp,
        originalOrderNo: this.bz3||"",
        itemId: ""
      })
        .then(response => {
          this.$toast.success("申请取消成功");
          this.fetchData();
          this.cancelLoading = false;
        })
        .catch(() => {
          this.cancelLoading = false;
        });
    }
  },
  activated() {
    this.order={}
    this.peoList=[]
    window.setTitle("订单详情");
    this.isLoading=true
    this.fetchData();
  }
};
</script>